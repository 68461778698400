<!--
 * @Author: Jerry
 * @Date: 2021-01-14 10:19:59
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-09 11:58:53
 * @Description: file content
-->
<template>
  <div id="register">
    <registers ref="registers" @validateSuccess="submit">
      <div slot="header" style="margin-bottom: 20px">
        <div class="header">
          <span class="orange">采购商注册</span>
        </div>
      </div>
      <div slot="radio"></div>
    </registers>
  </div>
</template>
<script>
import _ from "lodash";
import registers from "@/components/login/registers.vue";
import { userRegister } from "@/api/user";

export default {
  components: { registers },
  name: "Register",
  methods: {
    // 提交注册
    submit(formData) {
      // 角色类型(1：供应商，2：采购商)
      let roleType = "2";
      let data = _.pick(formData, [
        "nickName",
        "mobile",
        "password",
        "userType",
        "key",
        "code",
      ]);
      data.roleType = roleType;
      // 采购商固定为 3（企业）
      data.userType = '3';
      userRegister(data).then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          setTimeout(() => {
            this.$refs.registers.loading = false;
            this.$router.push({
              name: "UserLogin",
            });
          }, 1000);
        } else {
          this.$refs.registers.loading = false;
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#register {
  background: url("../../../assets/Login/pbig.png") no-repeat center center;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>