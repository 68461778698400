import { render, staticRenderFns } from "./registers.vue?vue&type=template&id=002a11eb&scoped=true&"
import script from "./registers.vue?vue&type=script&lang=js&"
export * from "./registers.vue?vue&type=script&lang=js&"
import style0 from "./registers.vue?vue&type=style&index=0&id=002a11eb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002a11eb",
  null
  
)

export default component.exports