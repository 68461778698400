<template>
  <div class="registers">
    <div class="wrap bg_fff">
      <slot name="header"></slot>
      <div class="center">
        <el-form
          :model="registerForm"
          :rules="registerRules"
          ref="registerForm"
        >
          <slot name="radio">
            <el-form-item prop="userType">
              <el-radio-group v-model="registerForm.userType">
                <el-row :gutter="110">
                  <el-col :span="8">
                    <el-radio label="1">自然人</el-radio>
                  </el-col>
                  <el-col :span="8">
                    <el-radio label="2">个体户</el-radio>
                  </el-col>
                  <el-col :span="8">
                    <el-radio label="3">企业</el-radio>
                  </el-col>
                </el-row>
              </el-radio-group>
            </el-form-item>
          </slot>
          <el-form-item prop="nickName">
            <el-input
              v-model="registerForm.nickName"
              placeholder="请输入您的昵称"
            >
              <!-- <img slot="prefix" src="../../assets/Login/my.png"> -->
              <span
                slot="prefix"
                class="iconfont icon-yonghuming loginiconfont"
              ></span>
            </el-input>
          </el-form-item>
          <el-form-item prop="mobile">
            <el-row :gutter="17">
              <el-col :span="16">
                <el-input
                  class="input"
                  v-model="registerForm.mobile"
                  placeholder="请输入您的手机号码"
                >
                  <span
                    slot="prefix"
                    class="iconfont icon-shouji loginiconfont"
                  ></span>
                </el-input>
              </el-col>
              <el-col :span="7">
                <!-- <el-button type="primary" @click="getCode"
                  >获取验证码</el-button
                > -->
                <verfiy-btn
                  @sendKey="getKey($event)"
                  :mobile="registerForm.mobile"
                ></verfiy-btn>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="registerForm.code"
              placeholder="请输入您的验证码"
            >
              <span
                slot="prefix"
                class="iconfont icon-yanzhengma loginiconfont"
              ></span>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="registerForm.password"
              placeholder="请输入您的登录密码"
              autocomplete="off"
            >
              <span
                slot="prefix"
                class="iconfont icon-mima loginiconfont"
              ></span>
            </el-input>
          </el-form-item>
          <el-form-item prop="pwdconfirm">
            <el-input
              type="password"
              v-model="registerForm.pwdconfirm"
              placeholder="请再次确认您的密码"
              autocomplete="off"
            >
              <span
                slot="prefix"
                class="iconfont icon-mima loginiconfont"
              ></span>
            </el-input>
          </el-form-item>
<!--          <el-form-item style="margin-top: -15px">-->
<!--            <el-checkbox label="已阅读并同意" v-model="agree"></el-checkbox>-->
<!--            <span class="agree">《</span>-->
<!--            <span class="orange agree">用户服务协议</span>-->
<!--            <span class="agree">》</span>-->
<!--          </el-form-item>-->
          <el-form-item class="bg-orange" style="margin-top: -10px">
            <el-button
              style="width: 100%; color: #fff"
              type="text"
              @click="submitForm('registerForm')"
              :loading="loading"
              >注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="footer" style="margin-top: -10px">
        未有账户，立即
        <router-link :to="{ name: 'UserLogin' }" style="color: #00f"
          >登录</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { checkMobileCodeByKey } from "@/api/user";
import verfiyBtn from "../verfiyBtn/verfiyBtn.vue";
export default {
  components: { verfiyBtn },
  name: "registers",
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.registerForm.pwdconfirm !== "") {
          this.$refs.registerForm.validateField("pwdconfirm");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.registerForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      registerForm: {
        userType: "",
        nickName: "",
        mobile: "",
        code: "",
        password: "",
        pwdconfirm: "",
      },
      registerRules: {
        userType: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        nickName: [
          { required: true, message: "请输入您的昵称", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入您的手机号码", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入您的验证码", trigger: "blur" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        pwdconfirm: [{ validator: validatePass2, trigger: "blur" }],
      },
      // 是否勾选用户服务协议
      agree: [],
      // 获取到的验证码
      validateCode: null,
      loading: false,
    };
  },
  created() {
    this.getKey();
  },
  methods: {
    // 获取验证码key
    getKey(val) {
      this.validateCode = val;
    },
    //提交注册
    submitForm(formName) {
      // if (this.agree.length === 0) {
      //   this.$message({
      //     message: "请阅读并勾选《用户服务协议》",
      //     type: "warning",
      //   });
      //   return false;
      // }
      //key和输入的验证码进行对比
      checkMobileCodeByKey(this.registerForm.code, this.validateCode).then(
        (res) => {
          if (res.success) {
            this.$message.success(res.msg);
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.loading = true;
                let registerForm = Object.assign(
                  {
                    key: this.validateCode,
                  },
                  this.registerForm
                );
                // 校验成功，返回注册信息
                this.$emit("validateSuccess", registerForm);
              }
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      );

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     this.loading = true;
      //     let registerForm = Object.assign(
      //       {
      //         key: this.validateCode,
      //       },
      //       this.registerForm
      //     );
      //     // 校验成功，返回注册信息
      //     this.$emit("validateSuccess", registerForm);
      //   }
      // });
    },
    // 获取手机验证码
    // getCode() {
    //   // 校验手机号码是否为空
    //   if (this.registerForm.mobile == "") {
    //     this.$message.error("请输入手机号码！");
    //     return false;
    //   }
    //   getMobileCode(this.registerForm.mobile).then((res) => {
    //     if (res.success) {
    //       this.validateCode = res.data;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  box-sizing: border-box;
  padding: 35px 150px;
  width: 690px;
  .header {
    text-align: center;
    margin-bottom: 20px;
    span {
      font-size: 30px;
      font-weight: bold;
    }
  }
  .center {
    .agree {
      font-size: 14px;
    }
    .huoqu {
      height: 42px;
      color: #fff;
      padding: 0 16px;
    }
  }
  .footer {
    text-align: center;
    font-size: 12px;
  }
}
.el-input:focus-within {
  span {
    color: #ff5b0c;
  }
}
</style>
